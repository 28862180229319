import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
//import '../components/scss/news.scss'
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const NewsPage = ({ data }) => (
  <Layout>
    <h1>News</h1>
    <SEO title="News" />

    {data.allMicrocmsNews.edges.map(edge => {
      const news = edge.node

      return (
        <React.Fragment key={news.id}>
          <div className='posts'>

            {news.image.url ? (
              <img
                src={news.image.url}
                alt={'投稿用画像'}
                style={{ width: '25vw', minWidth: '160px' }}
              />
            ) : (
                <img
                  src='../images/Circle.png'
                  alt={'投稿用画像'}
                  style={{ width: '25vw', minWidth: '160px' }}
                />
              )}
            <h2>{news.title}</h2>
            <div className='tag'>
              <LocalOfferIcon />
              <p>
                {news.tag.map(tag => (
                  <React.Fragment key={tag.id}>
                    <span>{tag.name}</span>
                  </React.Fragment>
                ))}
              </p>
            </div>
            <Link to={`/news/${news.id}`}>READ MORE</Link>
          </div>
        </React.Fragment>
      )
    })}
  </Layout>
)

export const query = graphql`
 {
  allMicrocmsNews(
    sort: {fields: [createdAt], order: DESC}) {
    edges {
      node {
        id
        title
        tag {
          id
          name
        }
          image {
            url
          }
          content
        }
      }
    }
 }
`

export default NewsPage